import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';

import {searchIcons} from '../AboutObject/components';
import Input from '../Input';
import Cards from '../Cards';
import Button from '../Button';
import {useModal} from '../../hooks/useModal';
import FileLoader from '../FileLoader';
import Loader from '../Loader';
import {useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {getObjectIdFromRoute} from '../../utils/getObjectIdFromRoute';
import {getContractIdFromRoute} from '../../utils/getContractIdFromRoute';
import {setProperties} from '../../utils/setProperties';
import {getAuthorizationHeader} from '../../utils/auth';
import {API_getConstructionCertificates, API_getGroups} from '../../utils/api';
import {fetchRequest} from '../../utils/fetchRequest';
import {filterItemsBySearchText} from '../../utils/filterItemsBySearchText';
import {getGroupIdFromRoute} from '../../utils/getGroupIdFromRoute';
import NoData from '../NoData';
import EditGroupUPModal from '../Forms/EditGroupUPModal';
import {getRouteUrl, getWatcherRouteUrl} from '../../utils/getRouteUrl';
import {setUpCount} from '../../features/upReducer';
import EditUPRecordModal from "../Forms/EditUPRecordModal";

const ObjectGroups = (props) => {
    const {allGroupsData, isWatcherUser} = props;

    const {isArchive} = useSelector(state => state.breadcrumbs);

    const route = useRouteMatch();
    const history = useHistory();
    const dispatch = useDispatch();

    const groupId = getGroupIdFromRoute(route);
    const objectId = getObjectIdFromRoute(route);
    const contractId = getContractIdFromRoute(route);

    const prepareUpGroups = useCallback((allItems) => {
        return allItems.map((item) => {
            const {
                id,
                name,
            } = item;

            const groupId = id;

            const propertiesItem = setProperties({
                id: {value: id},
                text: {value: name},
                route: {
                    value: isWatcherUser ? getWatcherRouteUrl({groupId, objectId}) : getRouteUrl({
                        contractId,
                        objectId,
                        objectType: 'UP',
                        groupId
                    })
                },
            });

            return propertiesItem;
        });
    }, [objectId, contractId]);

    const initialData = allGroupsData?.length ? prepareUpGroups(allGroupsData) : [];

    useEffect(() => {
        if (groupId) return;

        if (!allGroupsData || !allGroupsData.length) return;

        const [firstGroup] = allGroupsData;

        const firstGroupLink = getWatcherRouteUrl({objectId, groupId: firstGroup.id});
        history.push(firstGroupLink);
    }, [allGroupsData, objectId, groupId]);

    const [groupsData, setGroupsData] = useState(initialData);
    const [filteredGroupsData, setFilteredGroupsData] = useState(initialData);
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setLoading] = useState(false);

    const app = useSelector(state => state.app);

    const [showModal] = useModal({
        component: EditGroupUPModal,
        name: 'new-group',
        title: 'Новая группа УП',
        props: {objectId, contractId},
    });

    useEffect(() => {
        if (allGroupsData && allGroupsData.length) {
            const preparedData = prepareUpGroups(allGroupsData);

            setGroupsData(preparedData);
            setFilteredGroupsData(preparedData);
        }
    }, [allGroupsData]);

    const getGroupsData = useCallback(async () => {
        setLoading(true);
        const options = {
            headers: {
                'Authorization': getAuthorizationHeader(),
            },
        };

        const url = API_getGroups([{
            column: 'contract_object_id',
            operator: '=',
            value: objectId,
        }]);

        const {response, success} = await fetchRequest(url, options);
        const responseData = response?.data?.sort((a, b) => {
            const aNumber = a.name.match(/^\d+/g)
            const bNumber = b.name.match(/^\d+/g)
            if(aNumber && bNumber && aNumber[0] && bNumber[0]){
                return (+aNumber[0]) - (+bNumber[0])
            }else{
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }

                return 0;
            }
        }) || [];

        setLoading(false);

        // Если в ответе нет данных — возвращаемся на этап назад
        if (success && !responseData.length) {
            const routedUrl = getRouteUrl({contractId, objectId, objectType: 'UP'})
            history.push(routedUrl);

            return;
        }

        dispatch(setUpCount({
            upCount: response.items_count,
            upClosedCount: response.items_closed_count,
        }));

        return success && prepareUpGroups(responseData);
    }, [objectId, contractId]);

    const saveGroups = useCallback((preparedGroups) => {
        setGroupsData(preparedGroups);
        setFilteredGroupsData(preparedGroups);

        if (groupId) return;

        const [firstGroup] = preparedGroups;
        const firstGroupId = firstGroup?.id?.value;

        if (firstGroupId) {
            const firstGroupLink = getRouteUrl({
                contractId,
                objectId,
                objectType: 'UP',
                groupId: firstGroup?.id?.value
            });
            history.push(firstGroupLink, {id: firstGroup?.id});
        }
    }, [setGroupsData, setFilteredGroupsData, contractId, objectId, groupId, history]);

    useEffect(() => {
        if (isWatcherUser) return;

        const getData = async () => {
            const preparedGroups = await getGroupsData();

            if (preparedGroups) {
                saveGroups(preparedGroups);
            }
        };

        getData();
    }, [getGroupsData, isWatcherUser]);

    const handleSearchValue = useCallback(({value: searchValue}) => {
        setSearchValue(searchValue);

        if (!searchValue) {
            setFilteredGroupsData(groupsData);
            return;
        }

        const FILTERED_PROPERTIES = [
            'text',
        ];

        const filteredItems = filterItemsBySearchText(groupsData, FILTERED_PROPERTIES, searchValue);

        setFilteredGroupsData(filteredItems);
    }, [groupsData, setFilteredGroupsData]);

    const requestUrl = API_getConstructionCertificates();

    const onFileImportHandler = useCallback(async (files) => {
        const [file] = files;

        const requestData = new FormData();

        requestData.append('file', file);
        requestData.append('contract_object_id', objectId);

        const options = {
            method: 'POST',
            headers: {
                'Authorization': getAuthorizationHeader(),
            },
            body: requestData,
        };

        document.body.classList.add('body_loading');

        const {success} = await fetchRequest(requestUrl, options);

        document.body.classList.remove('body_loading');

        if (success) {
            alert('Импорт прошел успешно.')
            window.location.reload();
        } else {
            alert('Прозошла ошибка при импорте файла.')
        }
    }, [requestUrl, objectId]);

    return (
        <ObjectGroupsContainer show={app.sideMenu}>
            <SearchWrapper>
                <Input icon={searchIcons} value={searchValue} placeholder='Поиск по группам' name='search'
                       onChange={handleSearchValue} theme='dark'/>
            </SearchWrapper>
            <Loader isLoading={isLoading} fillValue='#fff'/>
            {!isLoading && !filteredGroupsData.length && (
                <NoData color={'white'}>
                    Ничего не найдено
                </NoData>
            )}
            {!isLoading && <CardsWrapper>
                <Cards disabled={isArchive} items={filteredGroupsData} theme='light' modalType='object-groups-up'
                       addBetween={showModal}/>
            </CardsWrapper>
            }
            {
                !isArchive &&
                <Controls>
                    <FileLoader label='Импорт из Excel' onLoad={onFileImportHandler} multiple={false}/>
                    <Button label={'Добавить новую группу УП'} onClick={showModal}/>
                </Controls>
            }
        </ObjectGroupsContainer>
    );
};

const ObjectGroupsContainer = styled.div`
    display: flex;
    opacity: ${({show}) => show ? '1' : '0'};
    margin-left: ${({show}) => show ? '0' : '-400px'};
    width: 400px;
    background: linear-gradient(135deg, rgba(54, 79, 107, 1) 0%, rgba(71, 104, 141, 1) 100%);
    border-radius: 0 ${({theme}) => theme.other.borderRadiusNormal} 0 0;
    padding: 10px 3px 10px 10px;
    flex-direction: column;
    overflow: hidden;
    flex: 0 0 auto;
    transition: opacity, transform, ${({theme}) => theme.transitions.normal};
`

const SearchWrapper = styled.div`
    margin-bottom: 10px;
    flex: 0 0 auto;
    padding-right: 8px;
`

const CardsWrapper = styled.div`
    overflow-y: auto;
    flex: 1 1 auto;
    padding-right: 3px;
    margin-bottom: 10px;

    ::-webkit-scrollbar-thumb {
        background-color: #ffffff;
    }
`

const Controls = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-right: 6px;

    > * {
        margin-bottom: 10px;

        :last-child {
            margin-bottom: 0;
        }
    }
`

export default ObjectGroups;